import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VideoBackground from "vue-responsive-video-background-player"
import PrimeVue from 'primevue/config';
import Card from 'primevue/card';
import MaterialKit from "./plugins/material-kit";
import SplitCarousel from "vue-split-carousel";
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuesax from 'vuesax'
import Embed from 'v-video-embed'
import 'vuesax/dist/vuesax.css' //Vuesax styles
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
// CSS's


Vue.config.productionTip = false;
//Componentes
Vue.component("video-background", VideoBackground);
Vue.component("Card", Card);




//Uses
Vue.use(Vuesax);
Vue.use(VueperSlides);
Vue.use(VueperSlide);
Vue.use(MaterialKit);
Vue.use(PrimeVue);
Vue.use(Embed);
Vue.use(SplitCarousel);
Vue.use(VueAxios, axios);
axios.defaults.baseURL = "https://www.csales.com.br";
const NavbarStore = {
  showNavbar: false
};

Vue.mixin({
  /* eslint-disable */
  data() {
    return {
      NavbarStore
    };
  }
});

new Vue({
  /* eslint-disable */
  router,
  render: h => h(App)
}).$mount("#app");
