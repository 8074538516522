<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="javascript:void(0)"
                @click="scrollToElement('homeSection')">
              Voltar ao topo
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }} Edex Confecções, por <a href="https://www.linkedin.com/in/caio-costa-a9606b187/" target="_blank" style="color:#e56533">Caio Pecellin</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  },
  methods:{
    scrollToElement(element) {
      let element_id = document.getElementById(element);
      if (element_id) {
        element_id.scrollIntoView({ block: "end", behavior: "smooth" });
      }
    }
  },
};
</script>
<style></style>
