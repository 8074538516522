<template>
  <div class="wrapper">
  
    <video-background 
    :src="videoHome"
    :sources="[
        {src: videoMobile, res: 900, autoplay: true}, 
        {src: videoMobile, res: 638, autoplay: true}
    ]"
    autoplay
    style="height: 50vh"
    id="homeSection"
 >
      <div class="card" style="background-color: rgba(0,0,0,0.3)">
      <div class='flex flex-column justify-content-center align-items-center' style="max-height: 500px; height: 100vh;">
        <div class="flex justify-content-center align-items-center">
            <div class="flex align-items-center justify-content-center text-header"> <p class="xl:text-7xl md:text-6xl md:mb-4 sm:text-xl sm:mb-5 m-3 text-3xl text-white">Edex Confecções</p></div>
        </div>
      </div>
    </div>
    </video-background>

    <div class="main main-raised">

      
      <div class="section">
          <div class="grid align-items-center justify-content-center">
            <h2 class="title font-light">Sobre nós</h2>
            </div>
            <div class="grid align-items-center justify-content-center">
            <div class="center">
              <vs-button @click="activeValue=!activeValue" style="background-color: #e65100;" class="floating">
              Conheça nossos Valores!
              </vs-button>
              <vs-dialog scroll overflow-hidden not-close auto-width v-model="activeValue">
                <template #header>
                  <h4 class="not-margin">
                    Mais a fundo com a <b>Edex Confecções</b>
                  </h4>
                </template>

                <h3 class="title font-light" style="color: #e65100;">Nossa Missão:</h3>
                <p class="text-lg m-2">A missão da <strong>Edex Confecções</strong> é produzir vestuários esportivos de alta qualidade que proporcionem conforto, desempenho e estilo aos nossos clientes. Trabalhamos em parceria com os melhores fornecedores de tecidos e acessórios do país, utilizando técnicas de modelagem tradicionais e modernas. Nosso compromisso é entregar produtos que atendam às demandas específicas de cada atividade esportiva e de lazer, oferecendo confiança e satisfação.</p>
                <h3 class="title font-light" style="color: #e65100;">Visão:</h3>
                <p class="text-lg m-2">Nossa visão é <b>ser reconhecida como uma confecção líder na indústria esportiva</b>, valorizada por nossa dedicação à qualidade e pela capacidade de se adaptar às tendências em constante evolução. Buscamos ampliar continuamente nosso portfólio de produtos, proporcionando opções de personalização que permitam expressar a individualidade de cada cliente. Queremos ser a confecção de referência que oferece vestuários esportivos de destaque, não apenas para atletas, mas para todos aqueles que valorizam o conforto e o estilo no dia a dia.</p>
                <h3 class="title font-light" style="color: #e65100;">Os Valores de quem acredita:</h3>
                <p class="text-lg m-2"><strong>Inovação:</strong> <br> Abraçamos a inovação como uma maneira de criar produtos que superem as expectativas e se destaquem no mercado.</p>
                <p class="text-lg m-2"><strong>Qualidade:</strong> <br> Nossa busca pela excelência guia nossos processos, desde a seleção de materiais até a produção, para entregar produtos de qualidade excepcional.</p>
                <p class="text-lg m-2"><strong>Integridade:</strong> <br> Operamos com honestidade e transparência em todas as nossas ações, mantendo nossos compromissos e construindo relacionamentos sólidos.</p>
                <p class="text-lg m-2"><strong>Paixão:</strong> <br> Nossa paixão pelo esporte e pela moda é refletida em cada peça que produzimos e impulsiona nosso comprometimento.</p>
                <p class="text-lg m-2"><strong>Respeito:</strong> <br> Valorizamos a diversidade, respeitamos as perspectivas individuais e promovemos um ambiente inclusivo para o crescimento de todos.</p>
                <h3 class="title font-light" style="color: #e65100;">Ética é inegociável:</h3>
                <p class="text-lg m-2">A ética empresarial na Edex Confecções é baseada em responsabilidade social e ambiental. <b>Comprometemo-nos</b> a operar de maneira sustentável, minimizando nosso impacto ambiental e promovendo práticas justas em todas as etapas da produção. Respeitamos os direitos humanos, aderimos a práticas comerciais éticas e buscamos contribuir positivamente com as comunidades em que estamos presentes. Nossa ética é o alicerce de nossa reputação e do valor que oferecemos a nossos clientes e à sociedade.</p>
                

                <template #footer>
                  <div class="footer-dialog">
                    <div class="New" style="font-size: 15px;">
                      Para conhecer mais sobre o dia a dia da Edex, nos visite no <b><a href="https://www.linkedin.com/company/edexconfec%C3%A7%C3%B5es/" style="color: #e65100;">Linkedin</a></b>
                    </div>
                  </div>
                </template>
              </vs-dialog>
            </div>
          </div>

            <div class="flex justify-content-center flex-wrap card-container m-3">
                <div class="flex  flex-column" style="max-width:800px; width: 100%; text-align: justify;">
                  <p class="text-lg m-1"> A Edex Confecções trilhou um caminho abrangente desde sua fundação em 2001 até alcançar sua estrutura atual. Ao longo dessa jornada, nos especializamos na produção de vestuários para uma variedade de atividades esportivas, com um compromisso inabalável com a qualidade e atendimento excepcionais. Nossa missão primordial é combinar conforto com tecnologia têxtil de ponta, enquanto também valorizamos a estética, para atender às demandas em constante crescimento do mercado Esportivo Fashion, que tem vindo a dominar cada vez mais as tendências urbanas.</p>
                  <p class="text-lg m-1"> Contando com uma equipe técnica altamente especializada e equipamentos de produção de ponta, garantimos a fabricação de produtos de alta qualidade e desempenho. A cada ano, torna-se evidente que a moda e os tecidos esportivos estão se entrelaçando ainda mais nos cenários esportivos e além, seja nas ruas, nas academias, nas pistas ou nas piscinas. Como resultado, nossas coleções estão sempre sintonizadas com as tendências globais para permanecerem relevantes no mercado.</p>
                  <p class="text-lg m-1"> A Edex Confecções está constantemente a par dessas inovações, tanto em termos de avanços tecnológicos nos componentes de nossos produtos quanto nas tendências de moda que emergem no mundo esportivo e fashion. Internamente, possuímos todos os departamentos necessários para o desenvolvimento de amostras e criação de coleções que atendam às necessidades específicas de cada cliente. Estamos empenhados em superar as expectativas e continuar a trilhar um percurso de excelência na indústria da moda esportiva.</p>
                </div>
                 <!--
                <div class="flex align-items-center justify-content-center" style="max-width:700px; width: 100%">
                  <img style="max-width: 100%" :src="about">
                </div>
              -->
            </div>
            <!--
            <div class="flex justify-content-center flex-wrap card-container m-3">

              <vs-avatar class="m-10" size="250" :badge="clickMissao" badge-color="success" @click="clickMissao=!clickMissao">
                <img :src="bike" alt="">
              </vs-avatar>

              <vs-avatar class="m-10" size="250" :badge="clickValores" badge-color="success" @click="clickValores=!clickValores">
                <img :src="uv" alt="">
              </vs-avatar>
              <vs-avatar class="m-10" size="250" :badge="clickVisao" badge-color="success" @click="clickVisao=!clickVisao">
                <img :src="corrida" alt="">
              </vs-avatar>
              <vs-avatar class="m-10" size="250" :badge="clickResp" badge-color="success" @click="clickResp=!clickResp">
                <img :src="natacao" alt="">
              </vs-avatar>

            </div>
          -->
      </div>
      <div class="section" id="productSection">
        
        <div class="flex align-items-center justify-content-center">
            <h2 class="title font-light">Nossos Produtos</h2>
          </div>
        <div class="grid align-items-center justify-content-center">
          <div id="aboutSection"></div>
          <vs-card type="1" style="margin: 10px 30px;" >
            <template #title>
              <h3>Fitness</h3>
            </template>
            <template #img>
              <img :src="fitness" alt="">
            </template>
            <template #text>
              <p>
                Nossos produtos de fitness foram projetados para acompanhar seu ritmo ativo. Combinando conforto e estilo, oferecemos uma linha diversificada de roupas esportivas que proporcionam liberdade de movimento e suporte durante seus treinos.
              </p>
            </template>
          </vs-card>
          
          <vs-card type="1" style="margin: 10px 30px">
            <template #title>
              <h3>Praia</h3>
            </template>
            <template #img>
              <img :src="praia" alt="">
            </template>
            <template #text>
              <p>
                Nossos trajes de banho e roupas de praia não apenas exibem designs modernos, mas também oferecem conforto e durabilidade para que você possa desfrutar da estação ao máximo. Dos biquínis aos maiôs, nossos produtos são perfeitos para quem procura moda praia que une estilo e funcionalidade.
              </p>
            </template>
          </vs-card>
          
          <vs-card type="1" style="margin: 10px 30px">
            <template #title>
              <h3>Ciclismo</h3>
            </template>
            <template #img>
              <img :src="bike" alt="">
            </template>
            <template #text>
              <p>
                Com tecidos respiráveis e cortes ergonômicos, nossas peças oferecem conforto e desempenho durante suas pedaladas. De jerseys a shorts acolchoados, nossa coleção de ciclismo tem tudo o que você precisa para uma jornada suave e estilosa.
              </p>
            </template>
          </vs-card>
          
          <vs-card type="1" style="margin: 10px 30px">
            <template #title>
              <h3>Esportes Aquáticos</h3>
            </template>
            <template #img>
              <img :src="natacao" alt="">
            </template>
            <template #text>
              <p>
                Projetados para proporcionar conforto e resistência na água, nossos trajes de banho são ideais para nadadores de todos os níveis. De maiôs elegantes a sungas de alto desempenho, nossos produtos garantem que você possa nadar com confiança e estilo.
              </p>
            </template>
          </vs-card>
          <vs-card type="1" style="margin: 10px 30px">
            <template #title>
              <h3>Esportes Aeróbicos</h3>
            </template>
            <template #img>
              <img :src="corrida" alt="">
            </template>
            <template #text>
              <p>
                Nossos produtos são criados com materiais leves que ajudam a melhorar o desempenho e a mobilidade. Desde camisetas de corrida de secagem rápida até shorts com ajuste perfeito, nossa coleção oferece o suporte necessário para cada passo da sua jornada de corrida.
              </p>
            </template>
          </vs-card>
          <vs-card type="1" style="margin: 10px 30px">
            <template #title>
              <h3>Produtos UV</h3>
            </template>
            <template #img>
              <img :src="uv" alt="">
            </template>
            <template #text>
              <p>
                Nossa linha UV inclui roupas inteligentes e elegantes que oferecem uma barreira eficaz contra os raios solares. Mantenha-se seguro e fashion ao ar livre, seja em atividades esportivas ou simplesmente desfrutando do ar livre.
              </p>
            </template>
          </vs-card>

        </div>
        
      </div>

      <div class="section" id="aboutSection">
        <div class="flex align-items-center justify-content-center">
          <div class="title" >
            <h2 class="title font-light">Clientes</h2>
          </div>
        </div>
        <div class="container" >
          <split-carousel>
            <h3 slot="left-arrow"><i class='material-icons'>keyboard_arrow_left</i></h3>
            <split-carousel-item style="margin-left: 30px;">
              <img src="../assets/img/clients/1.png">
            </split-carousel-item>
            <split-carousel-item style="margin-left: 30px;">
              <img src="../assets/img/clients/2.png">
            </split-carousel-item>
            <split-carousel-item style="margin-left: 30px;">
              <img src="../assets/img/clients/3.png">
            </split-carousel-item>
            <split-carousel-item style="margin-left: 30px;">
              <img src="../assets/img/clients/4.png">
            </split-carousel-item>
            <split-carousel-item style="margin-left: 30px;">
              <img src="../assets/img/clients/5.png">
            </split-carousel-item>
            <split-carousel-item style="margin-left: 30px;">
              <img src="../assets/img/clients/6.png">
            </split-carousel-item>
            <split-carousel-item style="margin-left: 30px;">
              <img src="../assets/img/clients/7.png">
            </split-carousel-item>
            <split-carousel-item style="margin-left: 30px;">
              <img src="../assets/img/clients/8.png">
            </split-carousel-item>
            <split-carousel-item style="margin-left: 30px;">
              <img src="../assets/img/clients/9.png">
            </split-carousel-item>
            <split-carousel-item style="margin-left: 30px;">
              <img src="../assets/img/clients/10.png">
            </split-carousel-item>
            <h3 slot="right-arrow"><i class='material-icons'>keyboard_arrow_right</i></h3>
          </split-carousel>
        </div>
      </div>


      <div class="section" id="tecnoSection">
        <div class="grid align-items-center justify-content-center">
          <h2 class="title font-light">Nossas tecnologias</h2>
        </div>
        <div class="grid align-items-center justify-content-center">
          <vs-button @click="active=!active" style="background-color: #e65100;" class="floating">
              Veja nosso Video!
            </vs-button>
            <vs-dialog v-model="active">
              <template #header>
                <h4 class="not-margin">
                  Tecnologias da <b>Edex Confecções</b>
                </h4>
              </template>
              <center><video width="600" height="420" controls>
              <source :src="video" type="video/mp4">
            </video></center> 
            </vs-dialog>
        </div>
        <div class="grid justify-content-center align-items-center">
          <div class="col-10 md:col-8  lg:col-6 ">
            <p class="text-lg m-2" style="text-align: justify;">Desde o <strong>estágio do molde</strong> até o toque final de acabamento, na Edex Confecções, buscamos a perfeição em cada etapa da produção. Contamos com os mais renomados fornecedores de tecidos e acessórios do país para assegurar a qualidade superior de nossos produtos. Nossas instalações internas são equipadas com setores especializados para realizar as seguintes etapas:</p>
          </div>
        </div>
        <!-- Tela para Desktop -->
        <div class="desktop grid justify-content-center align-items-center">

          <div class="grid justify-content-center align-items-center text-align-justify">
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised" :src="sft1" alt="Image"  preview />
            </div>
            <div class="col-10 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify; "><strong>Desenvolvimento com software de ponta:</strong> <br> Na Edex Confecções, o uso do software OptiTex é fundamental para o desenvolvimento e fabricação de roupas. Essa ferramenta avançada de design assistido por computador (CAD) permite a criação de moldes digitais com rapidez e precisão, facilitando ajustes e simulações virtuais antes da produção física, o que minimiza erros. O OptiTex integra o gerenciamento da produção e o planejamento de recursos empresariais, permitindo o monitoramento de todas as fases do processo, desde a aquisição de materiais até a entrega final, garantindo um ciclo produtivo fluido. Além disso, a capacidade de realizar simulações em 3D oferece uma visualização antecipada dos produtos, reduzindo retrabalhos e insatisfações. Com a automação do software, as máquinas se comunicam de forma eficiente, otimizando o fluxo de trabalho e diminuindo o tempo de inatividade. Esse investimento no OptiTex não apenas melhora a qualidade do produto final, mas também nos confere uma vantagem competitiva, permitindo respostas ágeis às tendências e demandas do mercado.</p>
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify">
            <div class="col-10 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify;"><strong>Revisão dos tecidos:</strong> <br> Essa etapa é crucial para garantir que o tecido esteja em perfeitas condições antes de ser processado. A máquina de revisão de tecidos é usada para inspecionar cada rolo de tecido, identificando defeitos como furos, manchas, variações de cor ou falhas na trama. Ela geralmente utiliza sensores e iluminação especializada para destacar falhas que podem passar despercebidas a olho nu. Durante a revisão, o tecido é desenrolado e passado por este equipamento, e os defeitos são marcados para correção ou substituição, garantindo que somente materiais de alta qualidade sejam utilizados nas etapas subsequentes. Isso é fundamental para evitar desperdícios e manter a qualidade das roupas finais.</p>
            </div>
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised" :src="equi1" alt="Image"  preview />
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify">
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised " :src="equi2" alt="Image"  preview />
            </div>
            
            <div class="col-8 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify;"><strong>Preparação para o corte:</strong> <br> A enfestadeira automática Bullmer é uma máquina avançada que prepara o tecido para o corte, organizando múltiplas camadas de material de forma precisa e eficiente. Esse processo é conhecido como "enfestar", e envolve alinhar e estender o tecido em camadas uniformes sobre uma mesa de corte. A máquina pode ajustar a tensão do tecido para evitar ondulações e garantir que o corte posterior seja exato em todas as camadas. A enfestadeira automática economiza tempo e garante maior precisão comparada ao processo manual, sendo fundamental para a produção em massa de roupas, pois aumenta a produtividade e reduz erros durante o corte.</p>
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify">
            <div class="col-8 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify;"><strong>Cortes de tecidos especiais:</strong> <br> A Bullmer S80 é uma máquina de corte automática de alta precisão, usada para cortar múltiplas camadas de tecido ao mesmo tempo, seguindo moldes digitais pré-determinados. Equipamentos como o Bullmer S80 são programados com desenhos técnicos das peças e utilizam lâminas ou lasers para realizar cortes exatos. Eles podem ser ajustados para cortar uma grande variedade de tecidos, desde algodão até materiais sintéticos e delicados. Essa máquina garante cortes consistentes e com alta velocidade, o que é essencial para manter a uniformidade em lotes grandes de produção. Além disso, ela minimiza o desperdício de material, já que o software otimiza o posicionamento dos moldes.</p>
            </div>
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised " :src="equi3" alt="Image"  preview />
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify">
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised " :src="equi4" alt="Image"  preview />
            </div>
            
            <div class="col-8 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify;"><strong>Costura em alta tecnologia:</strong> <br> Essas máquinas de costura de alta tecnologia utilizam avançados sistemas eletrônicos e mecânicos para realizar costuras rápidas, precisas e padronizadas. Elas podem ser programadas para diferentes tipos de pontos e técnicas de costura, desde simples pontos retos até costuras decorativas complexas. Muitas dessas máquinas são equipadas com sensores que ajustam a velocidade e a tensão de acordo com o tipo de tecido, garantindo que cada costura seja uniforme, sem falhas ou defeitos. Em grandes fábricas, essas máquinas são fundamentais para automatizar o processo de costura em massa, reduzindo a necessidade de intervenção manual e aumentando a eficiência na produção.</p>
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify">
            <div class="col-8 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify;"><strong>Costura automática para maior velocidade:</strong> <br> A máquina de costura automática é projetada para realizar tarefas específicas de costura sem a necessidade de supervisão constante de um operador. Com sistemas de alimentação automática de tecido e programas pré-definidos, ela executa tarefas como fazer bainhas, costurar bolsos, ou fechar partes da roupa repetidamente, com precisão e consistência. Ela é comumente utilizada para peças que requerem processos repetitivos, como costuras em áreas específicas, o que torna o processo mais rápido e diminui os erros humanos. Além disso, a automação permite que os operadores se concentrem em tarefas mais complexas, otimizando o tempo de produção.</p>
            </div>
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised " :src="equi5" alt="Image"  preview />
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify">
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised " :src="equi6" alt="Image"  preview />
            </div>
            
            <div class="col-8 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify;"><strong>Bordas perfeitas:</strong> <br> A máquina de bordar automatiza o processo de adição de bordados decorativos ou funcionais em peças de roupa. Ela é programada com desenhos digitais, que podem variar desde simples logos até desenhos mais complexos. Usando agulhas múltiplas e fios coloridos, a máquina segue o design com precisão, bordando em áreas específicas da peça. Ela é capaz de trabalhar em uma grande variedade de tecidos e oferece alta velocidade e consistência, permitindo que grandes lotes de peças sejam personalizadas com o mesmo padrão. Essas máquinas são amplamente utilizadas para criar roupas de marcas, uniformes ou peças decorativas que exigem detalhamento visual.</p>
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify">
            <div class="col-8 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify;"><strong>Para estilizar sua peça:</strong> <br> As máquinas termotransfer pneumáticas são utilizadas para aplicar estampas, etiquetas ou outros detalhes gráficos nas peças de roupas por meio de transferência térmica. Elas funcionam aplicando pressão e calor controlados para fixar imagens ou texturas em tecidos. O uso de sistemas pneumáticos permite uma pressão uniforme e ajustável, garantindo que o material transferido adira de forma perfeita à superfície do tecido, sem falhas ou desvios. Essa tecnologia é muito usada na produção de roupas esportivas, moda casual e em processos de personalização, pois oferece alta velocidade e a capacidade de aplicar gráficos detalhados com qualidade duradoura.</p>
            </div>
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised " :src="equi7" alt="Image"  preview />
            </div>
          </div>
        </div>

        <!-- Tela para Mobile -->
        <div class="mobile grid justify-content-center align-items-center"> 
          
          
          <div class="grid justify-content-center align-items-center text-align-justify" style="margin-top: 30px;">
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised  " :src="sft1" alt="Image"  preview />
            </div>
            <div class="col-10 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify; "><strong>Desenvolvimento com software de ponta:</strong> <br> Na Edex Confecções, o uso do software OptiTex é fundamental para o desenvolvimento e fabricação de roupas. Essa ferramenta avançada de design assistido por computador (CAD) permite a criação de moldes digitais com rapidez e precisão, facilitando ajustes e simulações virtuais antes da produção física, o que minimiza erros. O OptiTex integra o gerenciamento da produção e o planejamento de recursos empresariais, permitindo o monitoramento de todas as fases do processo, desde a aquisição de materiais até a entrega final, garantindo um ciclo produtivo fluido. Além disso, a capacidade de realizar simulações em 3D oferece uma visualização antecipada dos produtos, reduzindo retrabalhos e insatisfações. Com a automação do software, as máquinas se comunicam de forma eficiente, otimizando o fluxo de trabalho e diminuindo o tempo de inatividade. Esse investimento no OptiTex não apenas melhora a qualidade do produto final, mas também nos confere uma vantagem competitiva, permitindo respostas ágeis às tendências e demandas do mercado.</p>
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify" style="margin-top: 30px;">
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised  " :src="equi1" alt="Image"  preview />
            </div>
            <div class="col-10 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify; "><strong>Revisão de 100% dos tecidos:</strong> <br> Essa etapa é crucial para garantir que o tecido esteja em perfeitas condições antes de ser processado. A máquina de revisão de tecidos é usada para inspecionar cada rolo de tecido, identificando defeitos como furos, manchas, variações de cor ou falhas na trama. Ela geralmente utiliza sensores e iluminação especializada para destacar falhas que podem passar despercebidas a olho nu. Durante a revisão, o tecido é desenrolado e passado por este equipamento, e os defeitos são marcados para correção ou substituição, garantindo que somente materiais de alta qualidade sejam utilizados nas etapas subsequentes. Isso é fundamental para evitar desperdícios e manter a qualidade das roupas finais.</p>
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify" style="margin-top: 30px;">
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised  " :src="equi2" alt="Image"  preview />
            </div>
            
            <div class="col-10 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify; "><strong>Preparação para o corte:</strong> <br> A enfestadeira automática Bullmer é uma máquina avançada que prepara o tecido para o corte, organizando múltiplas camadas de material de forma precisa e eficiente. Esse processo é conhecido como "enfestar", e envolve alinhar e estender o tecido em camadas uniformes sobre uma mesa de corte. A máquina pode ajustar a tensão do tecido para evitar ondulações e garantir que o corte posterior seja exato em todas as camadas. A enfestadeira automática economiza tempo e garante maior precisão comparada ao processo manual, sendo fundamental para a produção em massa de roupas, pois aumenta a produtividade e reduz erros durante o corte.</p>
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify" style="margin-top: 30px;">
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised  " :src="equi3" alt="Image"  preview />
            </div>
            <div class="col-10 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify; "><strong>Cortes de tecidos especiais:</strong> <br> A Bullmer S80 é uma máquina de corte automática de alta precisão, usada para cortar múltiplas camadas de tecido ao mesmo tempo, seguindo moldes digitais pré-determinados. Equipamentos como o Bullmer S80 são programados com desenhos técnicos das peças e utilizam lâminas ou lasers para realizar cortes exatos. Eles podem ser ajustados para cortar uma grande variedade de tecidos, desde algodão até materiais sintéticos e delicados. Essa máquina garante cortes consistentes e com alta velocidade, o que é essencial para manter a uniformidade em lotes grandes de produção. Além disso, ela minimiza o desperdício de material, já que o software otimiza o posicionamento dos moldes.</p>
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify" style="margin-top: 30px;">
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised  " :src="equi4" alt="Image"  preview />
            </div>
            
            <div class="col-10 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify; "><strong>Costura em alta tecnologia:</strong> <br> Essas máquinas de costura de alta tecnologia utilizam avançados sistemas eletrônicos e mecânicos para realizar costuras rápidas, precisas e padronizadas. Elas podem ser programadas para diferentes tipos de pontos e técnicas de costura, desde simples pontos retos até costuras decorativas complexas. Muitas dessas máquinas são equipadas com sensores que ajustam a velocidade e a tensão de acordo com o tipo de tecido, garantindo que cada costura seja uniforme, sem falhas ou defeitos. Em grandes fábricas, essas máquinas são fundamentais para automatizar o processo de costura em massa, reduzindo a necessidade de intervenção manual e aumentando a eficiência na produção.</p>
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify" style="margin-top: 30px;">
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised  " :src="equi5" alt="Image"  preview />
            </div>
            <div class="col-10 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify; "><strong>Costura automática para maior velocidade:</strong> <br> A máquina de costura automática é projetada para realizar tarefas específicas de costura sem a necessidade de supervisão constante de um operador. Com sistemas de alimentação automática de tecido e programas pré-definidos, ela executa tarefas como fazer bainhas, costurar bolsos, ou fechar partes da roupa repetidamente, com precisão e consistência. Ela é comumente utilizada para peças que requerem processos repetitivos, como costuras em áreas específicas, o que torna o processo mais rápido e diminui os erros humanos. Além disso, a automação permite que os operadores se concentrem em tarefas mais complexas, otimizando o tempo de produção.</p>
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify" style="margin-top: 30px;">
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised  " :src="equi6" alt="Image"  preview />
            </div>
            
            <div class="col-10 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify; "><strong>Bordado perfeito:</strong> <br> A máquina de bordar automatiza o processo de adição de bordados decorativos ou funcionais em peças de roupa. Ela é programada com desenhos digitais, que podem variar desde simples logos até desenhos mais complexos. Usando agulhas múltiplas e fios coloridos, a máquina segue o design com precisão, bordando em áreas específicas da peça. Ela é capaz de trabalhar em uma grande variedade de tecidos e oferece alta velocidade e consistência, permitindo que grandes lotes de peças sejam personalizadas com o mesmo padrão. Essas máquinas são amplamente utilizadas para criar roupas de marcas, uniformes ou peças decorativas que exigem detalhamento visual.</p>
            </div>
          </div>

          <div class="grid justify-content-center align-items-center text-align-justify" style="margin-top: 30px;">
            <div class="col-8 md:col-4  lg:col-3">
              <img class="img-raised  " :src="equi7" alt="Image"  preview />
            </div>
            <div class="col-10 md:col-4  lg:col-3">
              <p class="text-lg m-2" style="text-align: justify; "><strong>Para estilizar sua peça:</strong> <br> As máquinas termotransfer pneumáticas são utilizadas para aplicar estampas, etiquetas ou outros detalhes gráficos nas peças de roupas por meio de transferência térmica. Elas funcionam aplicando pressão e calor controlados para fixar imagens ou texturas em tecidos. O uso de sistemas pneumáticos permite uma pressão uniforme e ajustável, garantindo que o material transferido adira de forma perfeita à superfície do tecido, sem falhas ou desvios. Essa tecnologia é muito usada na produção de roupas esportivas, moda casual e em processos de personalização, pois oferece alta velocidade e a capacidade de aplicar gráficos detalhados com qualidade duradoura.</p>
            </div>
          </div>
        </div>
      </div>


      <div class="section contato" id="contactSection">
        <div class="flex flex-column align-items-center justify-content-center m-3">
            
        </div>
        <div class="card">
          <div v-if="defaultPagination == 1" class="grid justify-content-center align-items-center">
            <div class="col-10 md:col-6  lg:col-4 ">
              <login-card header-color="orange">
                <h4 slot="title" class="card-title">Entre em contato</h4>
                
                <p slot="description" class="description">Qual sua dúvida?</p>
                <md-field class="md-form-group" slot="inputs">
                  <md-icon>face</md-icon>
                  <label>First Name...</label>
                  <md-input v-model="name1"></md-input>
                </md-field>
                <md-field class="md-form-group" slot="inputs">
                  <md-icon>email</md-icon>
                  <label>Email...</label>
                  <md-input v-model="email1" type="email"></md-input>
                </md-field>
                <md-field class="md-form-group" slot="inputs">
                  <md-icon>chat</md-icon>
                  <label>Mensagem...</label>
                  <md-input v-model="message1"></md-input>
                </md-field>
                <md-button slot="footer" class="font-bold md-primary" @click="SendEmailBasic()">
                  Enviar
                </md-button>
              </login-card>
            </div>
          </div>
          <div v-if="defaultPagination == 2" class="grid justify-content-center align-items-center">
            <div class="col-10 md:col-6  lg:col-4 ">
              <login-card header-color="orange">
                <h4 slot="title" class="card-title">Trabalhe Conosco</h4>
                
                <p slot="description" class="description">Gostaria de fazer parte do time? Nos deixe te conhecer!</p>
                <md-field class="md-form-group" slot="inputs">
                  <md-icon>face</md-icon>
                  <label>Nome...</label>
                  <md-input v-model="name2" type="text" require></md-input>
                </md-field>
                <md-field class="md-form-group" slot="inputs">
                  <md-icon>smartphone</md-icon>
                  <label>Telefone...</label>
                  <md-input v-model="telefone2" type="number" require></md-input>
                </md-field>
                <md-field class="md-form-group" slot="inputs">
                  <md-icon>email</md-icon>
                  <label>Email...</label>
                  <md-input v-model="email2" type="email" require></md-input>
                </md-field>
                <md-field class="md-form-group" slot="inputs">
                  <md-file v-model="file" type='file' ref="file" @change="selectedFiles" placeholder="Clique aqui para enviar o arquivo" require></md-file>
                </md-field>
                <md-button slot="footer" class="font-bold md-primary" @click="SendEmailFile">
                  Enviar
                </md-button>
              </login-card>
            </div>
            
          </div>
          <div v-if="!loginSucces" class="alert alert-success">
            <div class="container">
              <button
                type="button"
                aria-hidden="true"
                class="close"
                @click="removeNotifySuccess"
              >
                <md-icon>clear</md-icon>
              </button>
              <div class="alert-icon">
                <md-icon>check</md-icon>
              </div>

              <b> SUCCESS ALERT </b> : Sua mensagem foi enviada!
            </div>
          </div>
          <div v-if="!loginError" class="alert alert-danger">
              <div class="container">
                <button
                  type="button"
                  aria-hidden="true"
                  class="close"
                  @click="removeNotify"
                >
                  <md-icon>clear</md-icon>
                </button>
                <div class="alert-icon">
                  <md-icon>info_outline</md-icon>
                </div>
                <b> ERROR ALERT </b> : Por favor, preencha todos os campos do formulário
              </div>
          </div>
          <div class="grid">
            <div class="col-6 col-offset-3">
              <div class="flex align-items-center justify-content-center">
                <pagination v-model="defaultPagination" :page-count="2">
                </pagination>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="section">
        <div class="container">
          <div class="flex align-items-center justify-content-center">
            <h2 class="title font-light">Onde nos encontrar?</h2>
          </div>
        </div>
        <div class="grid align-items-center justify-content-center">
          <div class="col-10">
            <iframe src="https://www.google.com/maps/embed?pb=!4v1649705445442!6m8!1m7!1sbQiqTE_uvWsmokeE8XwRdw!2m2!1d-22.72583304857964!2d-47.36047872604014!3f153.99225!4f0!5f0.7820865974627469" style="border:0;width: 100%; height: 500px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- SLIDES
          <div class="col-10 md:col-10  lg:col-8 ">
            
            <p class="text-lg m-2"><strong>Corte:</strong> <br> Empregamos um sistema de encaixe automático para otimizar o uso de tecidos, garantindo eficiência e mínimo desperdício. Esse sistema também assegura a perfeita correspondência entre a modelagem desenvolvida e o produto final.</p>
            <p class="text-lg m-2"><strong>Customização:</strong> <br> Oferecemos uma variedade de opções de personalização, incluindo estampas sublimáticas, transfer e bordados. Isso permite que cada artigo seja único e atenda às preferências individuais.</p>
            <p class="text-lg m-2"><strong>Costura:</strong> <br> Nossas máquinas de costura estão equipadas para criar diversos tipos de acabamentos, conforme especificado no processo de desenvolvimento. Isso nos permite atender a uma ampla gama de estilos e requisitos.</p>
            <p class="text-lg m-2"><strong>Passadoria e Acabamento:</strong> <br> Nosso processo de finalização é abrangente. Realizamos passadoria sempre que necessário, revisão minuciosa peça por peça e embalagem cuidadosa para garantir que cada item atinja os padrões de qualidade da Edex Confecções antes de chegar às mãos de nossos clientes.</p>
            
            
          </div>

        
          <div class="col-10 md:col-6 lg:col-5 sm:col-6">
            <div class="grid align-items-center justify-content-center">
              <h3 class="title font-light">Conheça algumas de nossas máquinas!</h3>
            </div>
            <div class="slide">
              <vueper-slides
                autoplay
                :pause-on-hover="pauseOnHover"
                @autoplay-pause="internalAutoPlaying = false"
                @autoplay-resume="internalAutoPlaying = true"
                fixed-height="100%"
                >
                <vueper-slide
                  v-for="(slide, i) in slides"
                  :key="i"
                  :image="slide.image" >
                  <template #content>
                    <div class="grid justify-content-center align-items-center">
                      <h4 class="not-margin" style="top: 40%; color: white; background-color: #e65100; padding: 10px 15px;">
                        <b>{{slide.title}}</b>
                      </h4>
                    </div>
                  </template>
                </vueper-slide>
              </vueper-slides>
            </div>
          </div>
          
-->
<!--
      <div class="section" id="aboutSection">
        <div class="flex align-items-center justify-content-center">
          <div class="title" >
            <h2 class="title font-light">Clientes</h2>
          </div>
        </div>
        <div class="container" >
          <split-carousel>
            <h3 slot="left-arrow"><i class='material-icons'>keyboard_arrow_left</i></h3>
            <split-carousel-item>
              <img src="../assets/img/clients/1.png">
            </split-carousel-item>
            <split-carousel-item>
              <img src="../assets/img/clients/2.png">
            </split-carousel-item>
            <split-carousel-item>
              <img src="../assets/img/clients/3.png">
            </split-carousel-item>
            <split-carousel-item>
              <img src="../assets/img/clients/4.png">
            </split-carousel-item>
            <split-carousel-item>
              <img src="../assets/img/clients/5.png">
            </split-carousel-item>
            <split-carousel-item>
              <img src="../assets/img/clients/6.png">
            </split-carousel-item>
            <split-carousel-item>
              <img src="../assets/img/clients/7.png">
            </split-carousel-item>
            <split-carousel-item>
              <img src="../assets/img/clients/8.png">
            </split-carousel-item>
            <h3 slot="right-arrow"><i class='material-icons'>keyboard_arrow_right</i></h3>
          </split-carousel>
        </div>
      </div>
      -->
      <!--
      <div class="section" >
        <div class="container">
          <div class="flex align-items-center justify-content-center">
            <h2 class="title font-light">Equipamentos</h2>
          </div>
        </div>
        <div class="card">
          <div class="flex justify-content-center flex-wrap card-container">
            <div class="flex flex-column surface-card p-4 shadow-hover shadow-2 border-round m-2 mb-3 text-900" style="width: 500px">
                <div class="text-3xl font-medium mb-3">Máquina de Corte Automática</div>
                <img src="../assets/img/equipaments/1.png" style="max-width: 500px">
            </div>
            <div class="flex flex-column surface-card p-4 shadow-hover shadow-2 border-round m-2 mb-3 text-900" style="width: 500px">
                <div class="text-3xl font-medium text-900 mb-3">Máquina de Revisar</div>
                <img src="../assets/img/equipaments/2.png" style="max-width: 500px">
            </div>
            <div class="flex flex-column surface-card p-4 shadow-hover shadow-2 border-round m-2 mb-3 text-900" style="width: 500px">
                <div class="text-3xl font-medium text-900 mb-3">Máquina de Enfestar Automática</div>
                <img src="../assets/img/equipaments/3.png" style="max-width: 500px">
            </div>
            <div class="flex flex-column surface-card p-4 shadow-hover shadow-2 border-round m-2 mb-3 text-900" style="width: 500px">
                <div class="text-3xl font-medium text-900 mb-3">Thermo Transfer</div>
                <img src="../assets/img/equipaments/4.png" style="max-width: 500px">
            </div>
            <div class="flex flex-column surface-card p-4 shadow-hover shadow-2 border-round m-2 mb-3 text-900" style="width: 500px">
                <div class="text-3xl font-medium text-900 mb-3">Máquina de Vies</div>
                <img src="../assets/img/equipaments/5.png" style="max-width: 500px">
            </div>
            <div class="flex flex-column surface-card p-4 shadow-hover shadow-2 border-round m-2 mb-3 text-900" style="width: 500px">
                <div class="text-3xl font-medium text-900 mb-3">Bordadeira</div>
                <img src="../assets/img/equipaments/6.png" style="max-width: 500px">
            </div>
            
          </div>
        </div>

      </div>
      
      <div class="section">
          <div class="container">
            <div class="flex align-items-center justify-content-center">
              <h2 class="title font-light">Equipamentos</h2>
            </div>
          </div>
          <div class="grid justify-content-center">
            <div class="lg:col-6 md:col-9 col-12 justify-content-center">
              <md-card>
                <carousel
                  :per-page="1"
                  loop
                  :speed="700"
                  autoplay
                  :autoplay-timeout="3000"
                  :mouse-drag="false"
                  navigationEnabled
                  navigationNextLabel="<i class='material-icons'>keyboard_arrow_right</i>"
                  navigationPrevLabel="<i class='material-icons'>keyboard_arrow_left</i>"
                   style="max-height: 500px"
                >
                  <slide>
                    <div class="carousel-caption">
                      <h3 class="font-semibold">
                        Máquina de Corte Automática
                      </h3>
                    </div>
                    <img src="../assets/img/equipaments/1.png" style="max-width: 100%; height: 100%" alt="carousel1" />
                  </slide>
                  <slide>
                    <div class="carousel-caption">
                      <h3 class="font-semibold">
                        Máquina de Revisar
                      </h3>
                    </div>
                    <img src="../assets/img/equipaments/2.png" style="max-width: 100%; height: 100%" alt="carousel2" />
                  </slide>
                  <slide>
                    <div class="carousel-caption">
                      <h3 class="font-semibold">
                        Máquina de Enfestar Automática
                      </h3>
                    </div>
                    <img src="../assets/img/equipaments/3.png" style="max-width: 100%; height: 100%" alt="carousel3" />
                  </slide>
                  <slide>
                    <div class="carousel-caption">
                      <h3 class="font-semibold">
                        Thermo Transfer
                      </h3>
                    </div>
                    <img src="../assets/img/equipaments/4.png" style="max-width: 100%; height: 100%" alt="carousel1" />
                  </slide>
                  <slide>
                    <div class="carousel-caption">
                      <h3 class="font-semibold">
                        Máquina de Vies
                      </h3>
                    </div>
                    <img src="../assets/img/equipaments/5.png" style="max-width: 100%; height: 100%" alt="carousel2" />
                  </slide>
                  <slide>
                    <div class="carousel-caption">
                      <h3 class="font-semibold">
                        Bordadeira
                      </h3>
                    </div>
                    <img src="../assets/img/equipaments/6.png" style="max-width: 100%; height: 100%" alt="carousel3" />
                  </slide>
                </carousel>
              </md-card>
            </div>
          </div>

      </div>
      -->
      
<script>
/* eslint-disable */
//import { Modal } from "@/components";
import { Pagination } from "@/components";
//import HorizontalTeamCard from "@/examples/cards/teamCards/HorizontalTeamCard.vue";
import { LoginCard} from "@/components";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  bodyClass: "landing-page",
  props: {
    header: {
      type: String,
      default: require("@/assets/img/confeccao.jpg")
    },
    about: {
      type: String,
      default: require("@/assets/img/machine.png")
    },
    video: {
      type: String,
      default: require("@/assets/video/edex.mp4")
    },
    videoHome: {
      type: String,
      default: require("@/assets/video/edex_home.mp4")
    },
    videoMobile: {
      type: String,
      default: require("@/assets/video/edexMobile.mp4")
    },
    videoTablet: {
      type: String,
      default: require("@/assets/video/edexTablet.mp4")
    },
    emma: {
      type: String,
      default: require("@/assets/img/team5.jpg")
    },
    fitness: {
      type: String,
      default: require("@/assets/img/fitness.jpg")
    },
    praia: {
      type: String,
      default: require("@/assets/img/praia.jpg")
    },
    uv: {
      type: String,
      default: require("@/assets/img/uv.jpg")
    },
    bike: {
      type: String,
      default: require("@/assets/img/bike.jpg")
    },
    natacao: {
      type: String,
      default: require("@/assets/img/natacao.jpg")
    },
    corrida: {
      type: String,
      default: require("@/assets/img/corrida.jpg")
    },
    teste: {
      type: String,
      default: require("@/assets/img/teste.jpg")
    },
    equi1: {
      type: String,
      default: require("@/assets/img/equipaments/revisao.jpg")
    },
    equi2: {
      type: String,
      default: require("@/assets/img/equipaments/enfestadeira.jpg")
    },
    equi3: {
      type: String,
      default: require("@/assets/img/equipaments/corte_automatica.jpg")
    },
    equi4: {
      type: String,
      default: require("@/assets/img/equipaments/costura_alta.jpg")
    },
    equi5: {
      type: String,
      default: require("@/assets/img/equipaments/costura.jpg")
    },
    equi6: {
      type: String,
      default: require("@/assets/img/equipaments/bordar.jpg")
    },
    equi7: {
      type: String,
      default: require("@/assets/img/equipaments/termotransfer.jpg")
    },
    
    sft1: {
      type: String,
      default: require("@/assets/img/equipaments/software.jpg")
    },

  },
  data() {
    return {
      name: null,
      active: false,
      activeValue: false,
      email: null,
      message: null,
      classicModal: false,
      defaultPagination: 1,
      loginError: true,
      loginSucces: true,
      name1: "",
      name2: "",
      email1: "",
      email2: "",
      telefone2: "",
      message1: "",
      file:"",
      pauseOnHover: true,
      autoPlaying: true,
      internalAutoPlaying: true,
      clickMissao: true,
      clickVisao: true,
      clickValores: true,
      clickResp: true,
      slides: [
        {
          title: 'Revisão 100% dos tecidos',
          image: require("@/assets/img/equipaments/revisao.jpg")
        },
        {
          title: 'Enfestadeira automática Bullmer',
          image: require("@/assets/img/equipaments/enfestadeira.jpg")
        },
        {
          title: 'Máquina de corte automático Bullmer S80',
          image: require("@/assets/img/equipaments/corte_automatica.jpg")
        },
        {
          title: 'Máquinas de costura de alta tecnologia',
          image: require("@/assets/img/equipaments/costura_alta.jpg")
        },
        {
          title: 'Máquina de costura automática',
          image: require("@/assets/img/equipaments/costura.jpg")
        },
        {
          title: 'Máquina de bordar',
          image: require("@/assets/img/equipaments/bordar.jpg")
        },
        {
          title: 'Máquinas termotransfer pneumáticas',
          image: require("@/assets/img/equipaments/termotransfer.jpg")
        }
        // Other slides.
      ],
      slide_dev: [

      ]
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      }
    }
  },
  methods: {
    removeNotify() {
      this.loginError = true
    },
    removeNotifySuccess() {
      this.loginSucces = true
    },
    SendEmailBasic(){
      if (this.name1 != "" & this.email1 != "" & this.message1 != ""){
        var formdata = new FormData()
        formdata.append('name', this.name1)
        formdata.append('email', this.email1)
        formdata.append('message', this.message1)
        this.axios.post('/mailSimple.php',
          formdata, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(resp => {
          console.log(resp)
          this.loginSucces = false
        })
        .catch(erro =>{
          console.log(erro)
          this.loginError = false
        });
      }else{
        this.loginError = false
      }
    },
    SendEmailFile(){
      if (this.name2 != "" & this.email2 != "" & this.telefon2 != "" & this.files != ""){
        console.log(this.$refs)
        var formdata = new FormData()
        formdata.append('name', this.name2)
        formdata.append('email', this.email2)
        formdata.append('telefone', this.telefone2)
        formdata.append('file', this.files, this.files.name)
        console.log('>> formData >> ', formdata);
        this.axios.post('/mail.php',
          formdata, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(resp => {
          console.log(resp)
          this.loginSucces = false
        })
        .catch(erro =>{
          console.log(erro)
          this.loginError = false
        });
      }else{
        this.loginError = false
      }

    },
   selectedFiles (file) {
      this.files = file.target.files[0]
      console.log(this.files)
    }
  },
  components:{
    Pagination,
    LoginCard,
    VueperSlides, 
    VueperSlide
  }
};
</script>

<style lang="scss" scoped>

/* Oculta o conteúdo para celular */
.desktop {
      display: none;
  }

/* Exibe conteúdo para desktop/tablet */
@media (min-width: 768px) {
    .desktop {
        display: block;
    }
    .mobile {
        display: none;
    }
    .mobile img{
      width: 70%;
      height: 80%;
    }
    p{
      font-size: 12px;
    }
}

.text-header{
		font-family: 'Rubik Mono One', sans-serif;
    letter-spacing: -3px;
    }

  .shadow-hover:hover {
    box-shadow: 0px 6px 12px rgb(0 0 0 / 20%), 0px 0px 2px rgb(0 0 0 / 20%), 0px 2px 6px rgb(0 0 0 / 20%) !important;
    transition: all 0.5s;
}

.title{
  font-family: 'Roboto', sans-serif;
}
.contato {
  background-image: url("../assets/img/contato.jpg"); 
  background-repeat: no-repeat; 
  background-attachment: fixed;
  background-size: cover;
}

.floating {
  animation: floating 10s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0);
  transition: 0.5s;
}

@keyframes floating {
  0% {
    transform: translate3d(0, 0px, 0);
  }

  25% {
    transform: translate3d(0, -5px, 0);
  }

  50% {
    transform: translate3d(0, 2px, 0);
  }

  75% {
    transform: translate3d(0, -4px, 0);
  }

  90% {
    transform: translate3d(0, 4px, 0);
  }
}

.slide{
  height:auto;
  min-height:300px;
  /*height:auto !important;        * for IE as it does not support min-height */
  height:300px;  
}
</style>
